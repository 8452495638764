import React, { useEffect } from 'react';

import ProjectListItem from './ProjectListItem';
import { useIntersectionObserver } from '../hooks/useIntersectionObserver.js';

import ProjectContent from '../data/ProjectContent.json';

const ProjectList = () => {
  const toObserve = [];
  const intersectedEntry = useIntersectionObserver(toObserve, {});

  useEffect(() => {
    if (intersectedEntry.target) {
      intersectedEntry.target.classList.add('in-view');
    }
  });

  return (
    <div className="project-list">
      {Object.keys(ProjectContent)
        .reverse()
        .map(year => {
          const projects = ProjectContent[year];
          return (
            <div key={`projects-${year}`}>
              <p className="project-list__year">{year}</p>
              {projects.map((proj, projIdx) => (
                <ProjectListItem
                  ref={ref => toObserve.push(ref)}
                  key={`project-item-${projIdx}--work`}
                  project={proj}
                />
              ))}
            </div>
          );
        })}
    </div>
  );
};

export default ProjectList;
