import React from 'react';

import Layout from './layout.js';
import DynamicLink from 'components/DynamicLink';
import ProjectList from '../components/ProjectList';

const WorkArchive = () => (
  <Layout>
    <div className="work__wrapper">
      <div className="header__topper">
        <DynamicLink className="header__name" to="/">
          Caitlin Wang —{' '}
        </DynamicLink>
        <span className="header__description">Work Archive</span>
      </div>
      <div className="work-list">
        <ProjectList />
      </div>
    </div>
  </Layout>
);

export default WorkArchive;
