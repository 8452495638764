import React, { forwardRef, useState } from 'react';
import PropTypes from 'prop-types';
import DynamicLink from './DynamicLink.js';

const ProjectListItem = forwardRef(({ id, project }, ref) => {
  const [showImage, setShowImage] = useState(false);

  return (
    <div
      className="project-list__item"
      ref={ref}
      key={id}
      onMouseEnter={() => setShowImage(true)}
      onMouseLeave={() => setShowImage(false)}>
      <div className="project-list__item-info">
        <DynamicLink className="project-list__item-name" to={project.link}>
          {project.name}
        </DynamicLink>
        <img
          className={`project-list__item-thumbnail ${showImage && 'visible'}`}
          src={project.thumbnailSrc}
          alt={`${project.name} thumbnail`}
        />
        {' '}
        &nbsp;
        <span className="project-list__item-details">{project.work}</span>
      </div>
    </div>
  );
});

ProjectListItem.propTypes = {
  id: PropTypes.string,
  project: PropTypes.object,
};

ProjectListItem.displayName = 'ProjectListItem';

export default ProjectListItem;
